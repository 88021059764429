import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const HeadshotWrap = styled.div`
  width:310px;
  height: 310px;
  margin-bottom: 64px;
  `


const Headshot = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "headshothome" }, extension: {eq: "jpg"}) {
        childImageSharp{
          fluid(maxWidth:5000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `
  )
  return (
              <HeadshotWrap>
                <Img fluid={data.file.childImageSharp.fluid} alt="logo" className ="rounded"/>  
              </HeadshotWrap> 
  )
}

export default Headshot