import * as React from 'react';
import Headshot from "../components/staticImages/headshotimg.js"
import headshotimage from "../images/headshothome.jpg";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import * as styling from '../styles/styleconstants.js'




// data


// markup
const IndexPage = ({data}) => {
  return (
    <Layout>
    <main style={styling.pageStyles}>
      <title>Home Page</title>
      <h1 style={styling.headingStyles}>
        Liam Kaplan
        <br />
        <span style={styling.headingAccentStyles}>pianist and composer</span>  
      </h1>
      <Headshot/>
    </main>
    </Layout>
  )
}



export default IndexPage

